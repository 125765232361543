// import { any } from 'prop-types';
import React from "react"
import ContainerWrapper from "./style"

type Props = {
  // children: any
  className?: string
  fullWidth?: string
  noGutter?: any
  width?: string
  mobileGutter?: any
  children?: any
}
const Container: React.FC<Props> = ({
  children,
  className,
  fullWidth,
  noGutter,
  mobileGutter,
  width,
}) => {
  // Add all classs to an array
  const addAllClasses: string[] = ["container"]
  // className prop checking
  if (className) {
    addAllClasses.push(className)
  }

  return (
    <ContainerWrapper
      className={addAllClasses.join(" ")}
      fullWidth={fullWidth}
      noGutter={noGutter}
      width={width}
      mobileGutter={mobileGutter}
    >
      {children}
    </ContainerWrapper>
  )
}

export default Container
