import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Logo from "../../../components/UIElements/Logo"
import Container from "../../../components/UI/Container"
import FooterArea, {
  MenuArea,
  Menu,
  MenuItem,
  CopyrightText,
} from "./footer.style"

const Footer = props => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        footer {
          logo {
            publicURL
          }
          menu {
            id
            link
            text
          }
          widgets {
            id
            icon {
              publicURL
            }
            title
            description
          }
        }
      }
    }
  `)
  const { logo, menu, widgets } = data.appModernJson.footer
  const date = new Date()
  const year = date.getFullYear()

  return (
    <FooterArea>
      <Container>
        {/* <WidgetArea>
          {widgets.map(item => (
            <Box className="col" key={`footer-widget--key${item.id}`}>
              <Image src={item.icon.publicURL} alt={item.title} />
              <Heading as="h3" content={item.title} />
              <Text content={item.description} />
            </Box>
          ))}
        </WidgetArea> */}
        {/* End of footer widgets area */}
        <MenuArea>
          <Logo
            className="logo"
            href="/#home"
            logoSrc={logo.publicURL}
            title="App Classic"
          />
          <Menu>
            {menu.map(item => {
              if (item.text === "Home") {
                return (
                  <MenuItem key={`footer-link${item.id}`}>
                    <a href={props.isHomePage ? item.link : "/"}>{item.text}</a>
                  </MenuItem>
                )
              } else {
                return (
                  <MenuItem key={`footer-link${item.id}`}>
                    <a href={item.link} target="_blank">
                      {item.text}
                    </a>
                  </MenuItem>
                )
              }
            })}
          </Menu>
          <CopyrightText>© {year} razacar.</CopyrightText>
        </MenuArea>
        {/* End of footer menu area */}
      </Container>
    </FooterArea>
  )
}

export default Footer
