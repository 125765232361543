import React, { useState, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Fade from "react-reveal/Fade"
import ScrollSpyMenu from "../../../components/ScrollSpyMenu"
import Scrollspy from "react-scrollspy"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { Icon } from "react-icons-kit"
import { menu } from "react-icons-kit/feather/menu"
import { x } from "react-icons-kit/feather/x"
// import { search } from "react-icons-kit/feather/search"
import Logo from "../../../components/UIElements/Logo"
import Button from "../../../components/Button"
import Container from "../../../components/UI/Container"
import useOnClickOutside from "../../../hooks/useOnClickOutside"
import NavbarWrapper, { MenuArea, MobileMenu } from "./navbar.style"
import LogoImage from "../../../assets/image/appModern/Logo-Razacar_Horizontal-Logo.png"
import LogoImageAlt from "./../../../assets/image/appModern/logo2.png"
import './navbar.css'
const Navbar = (props) => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        navbar {
          logo {
            publicURL
          }
          navMenu {
            id
            label
            path
            offset
          }
        }
      }
    }
  `)
  const { navMenu } = data.appModernJson.navbar
  const [state, setState] = useState({
    search: "",
    searchToggle: false,
    mobileMenu: false,
  })

  const searchRef = useRef(null)
  useOnClickOutside(searchRef, () =>
    setState({ ...state, searchToggle: false })
  )

  const toggleHandler = type => {
    if (type === "search") {
      setState({
        ...state,
        search: "",
        searchToggle: !state.searchToggle,
        mobileMenu: false,
      })
    }

    if (type === "menu") {
      setState({
        ...state,
        mobileMenu: !state.mobileMenu,
      })
    }
  }

  // const handleOnChange = event => {
  //   setState({
  //     ...state,
  //     search: event.target.value,
  //   })
  // }

  // const handleSearchForm = event => {
  //   event.preventDefault()

  //   if (state.search !== "") {
  //     console.log("search data: ", state.search)

  //     setState({
  //       ...state,
  //       search: "",
  //     })
  //   } else {
  //     console.log("Please fill this field.")
  //   }
  // }

  const scrollItems = []

  navMenu.forEach(item => {
    scrollItems.push(item.path.slice(1))
  })

  const handleRemoveMenu = () => {
    setState({
      ...state,
      mobileMenu: false,
    })
  }

  return (
    <NavbarWrapper className="navbar">
      <Container>
        <Logo
          href="/#home"
          logoSrc={LogoImage}
          title="App Modern"
          className="main-logo"
        />
        <Logo
          href="/#home"
          logoSrc={LogoImageAlt}
          title="App Modern"
          className="logo-alt"
        />
        {/* end of logo */}
        { <MenuArea className={state.searchToggle  ? "active" : ""}>
          {props.isTrue ? <ScrollSpyMenu className="menu navStyle" menuItems={navMenu} offset={-84} /> :   <a
                        href={`/`}
                        offset="84"
                       
                        className="menu home-btn"
                      >
                        Home
                      </a>}
          
          {/* end of main menu */}

       

          

         
          {props.isTrue ?  <Button
            className="menubar"
            icon={
              state.mobileMenu ? (
                <Icon className="bar" icon={x} />
              ) : (
                <Fade>
                  <Icon className="close" icon={menu} />
                </Fade>
              )
            }
            color="#0F2137"
            variant="textButton"
            onClick={() => toggleHandler("menu")}
          /> : ''}
        </MenuArea> }
        
      </Container>

      {/* start mobile menu */}
      {props.isTrue ? <MobileMenu className={`mobile-menu ${state.mobileMenu  ? "active" : ""}`}>
        <Container>
          <Scrollspy
            className="menu"
            items={scrollItems}
            offset={-84}
            currentClassName="active"
          >
          
            {navMenu.map((menu, index) => {

                return (
                  (
                    <li key={`menu_key${index}`}>
                      <AnchorLink
                        href={menu.path}
                        offset={menu.offset}
                        onClick={handleRemoveMenu}
                        // className={newStyle.onHoverColor}
                        
                      >
                        {menu.label}
                      </AnchorLink>
                    </li>
                  )
                )
            }) }
           
          </Scrollspy>
        </Container>
      </MobileMenu> :   
     ''}
      
      {/* end of mobile menu */}
    </NavbarWrapper>
  )
}

export default Navbar
